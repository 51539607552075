import { makeRequest, notify } from "@utils/helpers";
import { Button, Col, Form, Input, Row, Select, Switch } from "antd";
import React, { useEffect, useState } from "react";

import { PlusOutlined } from '@ant-design/icons';
import { createVersion, getVersion } from "../../userManagement/users/requests";

const Dashbord = () => {
  const [loader, setLoader] = useState(false);
  const [form] = Form.useForm();

  const onFinish = (data) => {
    let payload = {
      ...data
    }

    makeRequest(setLoader, createVersion, payload, onSuccess, null);

  }

  const onSuccess = (response) => {
    notify("Saved Successfuly")
  }
  useEffect(() => {
    get();
    // eslint-disable-next-line
  }, []);

  const get = () => {

    makeRequest(setLoader, getVersion, null, getSuccess, null);
  }
  const getSuccess = (data) => {
    form.setFieldsValue({ versions: data[0].versions, test: data[0].test })

  }
  return (
    <div>
      <Row justify="center" >
        <Col span={16}>
          <Form onFinish={onFinish} layout="vertical" disabled={true} form={form} >

            <Row style={{ width: "100%" }} justify="space-around" >
              <Col span={11}>

                <Form.Item name="versions" label="Update note :" className="da-mb-16"
                >
                  <Input />
                </Form.Item>

              </Col>
              <Col span={11}>

                <Form.Item name="test" label="Test :" className="da-mb-16"
                >
                  <Select>
                    <Select.Option value={true}>Yes</Select.Option>
                    <Select.Option value={false}>No</Select.Option>
                  </Select>
                </Form.Item>

              </Col>
            </Row>

            <Row justify="center" >

              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ marginLeft: "5px" }}
                  loading={loader}
                >
                  Update
                </Button>
              </Form.Item>
            </Row>
          </Form>
        </Col>
      </Row>
    </div>
  )
}
export default Dashbord