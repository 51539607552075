import React from "react";
import PropTypes from "prop-types";

const DashboardIcon = ({ className, fill, ...rest }) => {
  return (
    <svg
      className={className}
      {...rest}
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.5 14.4375H17.875V17.875H16.5V14.4375ZM13.75 11H15.125V17.875H13.75V11ZM7.5625 17.875C6.65115 17.8739 5.77745 17.5114 5.13303 16.867C4.48861 16.2226 4.12609 15.3488 4.125 14.4375H5.5C5.5 14.8454 5.62096 15.2442 5.84759 15.5834C6.07422 15.9225 6.39634 16.1869 6.77322 16.343C7.15009 16.4991 7.56479 16.54 7.96487 16.4604C8.36496 16.3808 8.73246 16.1844 9.02091 15.8959C9.30935 15.6075 9.50579 15.24 9.58537 14.8399C9.66495 14.4398 9.62411 14.0251 9.468 13.6482C9.3119 13.2713 9.04754 12.9492 8.70836 12.7226C8.36919 12.496 7.97042 12.375 7.5625 12.375V11C8.47418 11 9.34852 11.3622 9.99318 12.0068C10.6378 12.6515 11 13.5258 11 14.4375C11 15.3492 10.6378 16.2235 9.99318 16.8682C9.34852 17.5128 8.47418 17.875 7.5625 17.875Z"
        fill={fill}
      />
      <path
        d="M19.25 1.375H2.75C2.38544 1.37536 2.03591 1.52035 1.77813 1.77813C1.52035 2.03591 1.37536 2.38544 1.375 2.75V19.25C1.37536 19.6146 1.52035 19.9641 1.77813 20.2219C2.03591 20.4797 2.38544 20.6246 2.75 20.625H19.25C19.6145 20.6245 19.9639 20.4794 20.2217 20.2217C20.4794 19.9639 20.6245 19.6145 20.625 19.25V2.75C20.6246 2.38544 20.4797 2.03591 20.2219 1.77813C19.9641 1.52035 19.6146 1.37536 19.25 1.375ZM19.25 7.5625H9.625V2.75H19.25V7.5625ZM8.25 2.75V7.5625H2.75V2.75H8.25ZM2.75 19.25V8.9375H19.25L19.2514 19.25H2.75Z"
        fill={fill}
      />
    </svg>
  );
};

DashboardIcon.defaultProps = {
  fill: "#0093CD",
  className: "",
};

DashboardIcon.propTypes = {
  fill: PropTypes.string,
  className: PropTypes.string,
};

export default DashboardIcon;
