import DashboardIcon from "./icons/DashboardIcon";
import UsersIcon from "./icons/UsersIcon";


const navigation = [{
        id: "dashboard",
        title: "Dashboard",
        icon: < DashboardIcon / > ,
        uri: "",
        permissionKey: "always",
    }, {
        id: "userManagement",
        title: "Users",
        icon: < UsersIcon / > ,
        uri: "/user-management",
        permissionKey: "hasChildren",
        children: [{
                id: "users",
                title: "Users",
                uri: "/users",
                permissionKey: "users_index",
            },

        ],
    },


];

export default navigation;