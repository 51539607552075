import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Col, Form, Input, Row } from "antd";

import { ButtonComponent } from "@comps/components";
import { makeRequest, getErrorProps, notify } from "@utils/helpers";
import loginLogo from '../../../../assets/images/logo/logo.png'

import AuthCommon from "../components/AuthCommon";
import { forget, verified } from "../requests";


const EmailVerification = () => {

  const history = useHistory();
  const [loader, setLoader] = useState(false);
  const [errors, setErrors] = useState([]);
  const [isUpdate, setIsUpdate] = useState(false);

  const onFinish = (payload) => {
    const queryString = window.location.search;
    const parameters = new URLSearchParams(queryString);
    const value = parameters.get('key');
    const load = {
      key: value,
      ...payload
    }
    makeRequest(setLoader, forget, load, onSuccess, onError);
  }
  useEffect(() => {
    const queryString = window.location.search;
    const parameters = new URLSearchParams(queryString);
    const value = parameters.get('id');
    const load = {
      user: value,

    }
    makeRequest(setLoader, verified, load, onSuccess, onError);
  }, [])

  const onSuccess = (data, res) => {
    notify("Email verified", res.msg);
    setIsUpdate(true)
  }

  const onError = (err) => {

    let errorList = [];
    errorList['email'] = "Something went wrong";
    setErrors(errorList);
  }
  return (
    <Row gutter={[32, 0]} className="da-authentication-page">
      <AuthCommon />

      <Col md={12}>
        <Row className="da-h-100" align="middle" justify="center">

          <Col
            xxl={11}
            xl={15}
            lg={20}
            md={20}
            sm={24}
            className="da-px-sm-8 da-pt-24 da-pb-48"
          >
            <Col style={{marginBottom:"40px"}} span={24} >
              <Row justify="center" align="middle" >

                <img   src={loginLogo} alt="" />
              </Row>

            </Col>
            <Row justify="center" align="middle" >
              <Col span={24} >
                <Row justify="center" align="middle" >

                  <h1 className="da-mb-sm-0">Blingar</h1>
                </Row>

              </Col> <Col span={24} >
                <Row justify="center" align="middle" >

                  <h4 className="da-mb-sm-0">Email verified successfuly</h4>
                </Row>

              </Col>
            </Row>

          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default EmailVerification;

const rules = {
  email: [
    { type: "email", message: "The input is not valid email!" },
    { required: true, message: "Please input your email!" },
  ]
};
