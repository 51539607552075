import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { TabComponent } from "@comps/components";
import { makeRequest } from "@utils/helpers";
import { getUser } from "../requests";

import BasicInformation from "./view/BasicInformation";
import Permission from "./view/Permission";


const pageConfig = {
  headers: {
    title: "View User",
    breadcrumb: [
      {
        name: "Users",
        path: "/user-management/users"
      },
      {
        name: "View",
      }
    ]
  }

}

const ViewUser = () => {
  const { id } = useParams();
  const [loader, setLoader] = useState(true);

 






  const tabs = [
    {
      title: "Events",
      content: <BasicInformation   disable={true} id={id} />
    },
    {
      title: "Badges",
      content: <Permission  disable={true} />
    },
  
 
  ]

  const onError = (res) => {
    // handle call failed error
  }

  
  return <TabComponent headers={pageConfig.headers} tabs={tabs} ></TabComponent>;
}

export default ViewUser;
