import { TableComponent } from "@comps/components";
import { makeRequest } from "@utils/helpers";
import { Button, Col, Input, Row, Space, Table, Tag, Tooltip } from "antd";
import { useEffect, useState, useRef } from "react";
import { getEvents } from "../../requests";
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { formatCompleteData, timeFormate, formatCompleteDataTime } from "../../../../../utils/helpers";
import moment from "moment";


const BasicInformation = (props) => {
  const [searchedColumn, setSearchedColumn] = useState('');
  const [searchText, setSearchText] = useState('');

  const searchInput = useRef(null);
  const [loader, setLoader] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    sortName: 'id',
    sortType: 'desc'
  });
  const handleTableChange = (page, fil, sorter) => {
    let payload = {
      ...pagination,
      current: page.current,
      pageSize: page.pageSize,
      sortName: sorter.field || 'id',
      sortType: sorter.order === 'ascend' ? 'asc' : 'desc',
    };
    setPagination(payload);
  }

  useEffect(() => {
    getAllUsers();
    // eslint-disable-next-line
  }, [pagination]);

  const getAllUsers = () => {
    let payload = {
      id: props.id
    };
    makeRequest(setLoader, getEvents, payload, onSuccess, null);
  }
  const onSuccess = (response) => {
    let data = []
    response.forEach(element => {
      data.push({
        date: formatCompleteData(element.eventDate),
        eventTime: timeFormate(element.time),
        ...element
      })
    });

    setDataSource(data);

  }
  const onError = (error, msg) => {
    //
  }
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };


  const columns = [
    {
      key: 'eventName',
      title: 'Name',
      dataIndex: 'eventName',
      // sorter: true,
      ...getColumnSearchProps('eventName'),

    },
    {
      key: 'eventType',
      title: 'Event Type',
      dataIndex: 'eventType',
      sorter: false,
      ...getColumnSearchProps('eventType'),

    },
    {
      key: 'city',
      title: 'City',
      dataIndex: 'city',
      sorter: false,
      ...getColumnSearchProps('city'),

    },
    {
      key: 'victoryCity',
      title: 'Victory City',
      dataIndex: 'victoryCity',
      sorter: false,
      ...getColumnSearchProps('victoryCity'),

    },
    {
      key: 'position',
      title: 'Placed',
      dataIndex: 'position',
      sorter: false,
      ...getColumnSearchProps('position'),

    },
    {
      key: 'totalpeople',
      title: 'Total',
      dataIndex: 'totalpeople',
      sorter: false,
      ...getColumnSearchProps('totalpeople'),

    },
    {
      key: 'virtual',
      title: 'Virtual',
      dataIndex: 'virtual',
      sorter: false,
      ...getColumnSearchProps('virtual'),

    },
    {
      key: 'date',
      title: 'Date',
      dataIndex: 'date',
      render: (date) => {
        const formattedDate = moment(new Date(date), 'MM DD, YYYY').format('MMM DD, YYYY');
        const parsedDate = moment(new Date(date), 'MM DD, YYYY');
        const day = parsedDate.date() < 10 ? `0${parsedDate.date()}` : parsedDate.date();
        const month = parsedDate.month() < 9 ? `0${parsedDate.month() + 1}` : parsedDate.month() + 1;
        const year = parsedDate.year();
    
        return `${month}/${day}/${year}`;
      },
    },    
    // {
    //   key: 'eventTime',
    //   title: 'Time',
    //   dataIndex: 'eventTime',

    // },
    // {
    //   key: 'image',
    //   title: 'image',
    //   dataIndex: 'image',
    //   render: (image) => (
    //     <span>


    //           <Tooltip placement="topLeft" title={`https://api-dev.blingar.cloud/${image}`}>
    //             <Tag onClick={() => window.open(`https://api-dev.blingar.cloud/${image}`, "mozillaTab")} color={"geekblue"}>
    //               {/* <a href="www.facebook.com" target="_blank|_self|_parent|_top|framename" > */}

    //               Click
    //               {/* </a> */}
    //             </Tag>
    //           </Tooltip>


    //     </span>
    //   ),

    // },
    {
      key: 'goals',
      title:
        <Row justify="space-between" >
          <Col span={11} >Goals </Col>
          <Col span={11} ><span style={{ color: "green" }} >yes</span>/<span style={{ color: "red" }} >no</span></Col>
        </Row>
      ,
      dataIndex: 'goals',
      render: (goals) => (
        <span>
          {goals.map((goals) => {
            let color = goals.achieved == "yes" ? 'green' : 'red';

            return (

              <Tag className="da-mb-6" color={color} key={goals._id}>
                {goals.goal.toUpperCase()}
              </Tag>
            );
          })}
        </span>
      ),

    },
    {
      key: 'links',
      title: 'Portal',
      dataIndex: 'links',
      render: (links) => (
        <span>
          {links.map((links) => {
            let color = links.title == "yes" ? 'green' : 'geekblue';

            return (

              <Tooltip placement="topLeft" title={links.url}>
                <Tag onClick={() => window.open(links.url, "mozillaTab")} color={color} key={links._id}>
                  {/* <a href="www.facebook.com" target="_blank|_self|_parent|_top|framename" > */}

                  {links.title.toUpperCase()}
                  {/* </a> */}
                </Tag>
              </Tooltip>

            );
          })}
        </span>
      ),

    },
    {
      key: 'journal',
      title: 'Journal',
      dataIndex: 'journal',
      render: (journal) => (
        <span>

          <Tag color={"gold"} >
            {journal.pre.toUpperCase()}
          </Tag>
          <Tag color={"gold"} >
            {journal.race.toUpperCase()}
          </Tag>
          <Tag color={"gold"} >
            {journal.post.toUpperCase()}
          </Tag>
          <Tag color={"gold"} >
            {journal.Next.toUpperCase()}
          </Tag>
          <Tag color={"gold"} >
            {journal.Notes.toUpperCase()}
          </Tag>

        </span>
      ),

    },





  ];
  return (
    <>
      <Table scroll={{
        x: 2000,

      }} loading={loader} columns={columns} dataSource={dataSource} pagination={true} />
    </>
  );
}

export default BasicInformation;
