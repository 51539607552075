import React from "react";


const RecoverPassword = () => {
    return (
        <div>
            hellossss
        </div>
    )
}
export default RecoverPassword;