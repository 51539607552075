import { get, post, del, put, postFd } from "@utils/axios";

const api = "user/user-list";
const events = "event/user?type=asc&sort=eventName";
const badges = "badges/user";
const version = "versionUpdate"

export const getEvents = (payload) => {
    return post(events, payload);
}

export const getBadlges = (payload) => {
    return post(badges, payload);
}
export const getUsers = (payload) => {
    return get(api, payload);
}
export const resetAll = (payload) => {
    return post("event/reset/all", payload);
}
export const deleteUser = (payload) => {
    return post("user", payload);
}
export const editUser = (payload) => {
    return post("user/update", payload);
}
export const createVersion = (payload) => {
    return post(version, payload);
}
export const getVersion = (payload) => {
    return get(version, payload);
}
export const getFilters = () => {
    return get(`${api}/filters`);
}

export const getUserDependencies = () => {
    return get(`${api}/dependencies`);
}




export const getUser = (id) => {
    return get(`${api}/${id}`);
}




export const updateUser = (payload) => {
    return put(`${api}/${payload.id}`, payload);
}