import { post } from "@utils/axios";

export const login = (payload) => {

    return post("user/login", payload, false);
}
export const forget = (payload) => {

    return post("user/change-password", payload, false);
}
export const verified = (payload) => {

    return post("user/verify-email", payload, false);
}
export const reset = (payload) => {
    return post("reset-password", payload, false);
}